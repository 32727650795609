/*
🦎

Active – active link variant handling for Framer (Dashfolio Edition) (https://hoferdo.link/active-framer)

v1.0.0

© 2023 by Dominik Hofer (https://hoferdo.link/me – Contact: hi at dominikhofer dot me)

Disclaimer:

This software is provided as-is, without any warranty. Use of this software and its constituent components is subject to the following terms:

1. Upon purchase of a license, the purchaser is granted the right to use this component in an unlimited number of Framer projects that are owned by the purchaser.

2. Modification, redistribution, or re-use of the source code, in whole or in part, without explicit written permission from the copyright holder (Dominik Hofer) is strictly prohibited.

3. This component may not be used in a commercial Framer project (i.e. a project that is used to generate revenue) unless the purchaser has purchased a Commercial or Creator license.

4. This component may not be used in a Framer project that is owned by a third party (e.g. a client) unless the third party has purchased a license for this component.

5. This component may not be used in a Framer template that you plan to distribute (either for free or for sale) unless you have purchased a Creator license.

By using this software, you agree to these terms. Failure to comply with these terms may result in legal consequences. If you have any questions regarding these terms, please contact Dominik Hofer through the provided link above.
*/

import type { ComponentType } from "react"
import { atom, useAtom } from "jotai"
import React from "react"

type LinkProps = {
    id: string
    layoutId?: string
    height: string
    width: string
    variant?: string
}

// Create an atom for window width
const windowWidthAtom =
    typeof window !== "undefined" ? atom(window.innerWidth) : atom(0)

export const withActiveLinkVariant = (
    Component: ComponentType<LinkProps>
): ComponentType<LinkProps> => {
    return (props: LinkProps) => {
        const lizardContainerRef = React.useRef<HTMLElement | null>(null)
        const [isActive, setIsActive] = React.useState(false)
        const [sidebarState, setSidebarState] = React.useState(
            localStorage.getItem("dashfolioSidebarState")
                ? JSON.parse(
                      localStorage.getItem("dashfolioSidebarState") as string
                  )
                : false
        )
        const [windowWidth, setWindowWidth] = useAtom(windowWidthAtom)

        const checkActiveLink = () => {
            const container = lizardContainerRef.current

            if (container) {
                const link = container.querySelector("a")
                if (link) {
                    const currentPath = window.location.pathname
                    const href = link.getAttribute("href")
                    if (href) {
                        // Transform href to absolute href for comparison
                        const absoluteHref = new URL(href, window.location.href)
                            .pathname

                        if (currentPath === absoluteHref) {
                            setIsActive(true)
                        } else {
                            setIsActive(false)
                        }
                    } else {
                        console.error(`🦎 Error: No href attribute found.`)
                    }
                } else {
                    console.error(`🦎 Error: No link found.`)
                }
            } else {
                console.error(
                    `🦎 Error: The active link variant isn't working properly. Check the docs for help.`
                )
            }
        }

        React.useEffect(() => {
            checkActiveLink()
        }, [])

        if (typeof window !== "undefined") {
            React.useEffect(() => {
                const handleResize = () => {
                    setWindowWidth(window.innerWidth)
                }

                window.addEventListener("resize", handleResize)

                // Cleanup
                return () => {
                    window.removeEventListener("resize", handleResize)
                }
            }, [])

            React.useEffect(() => {
                const handleSidebarChange = () => {
                    // Update the component state here
                    const newState = localStorage.getItem(
                        "dashfolioSidebarState"
                    )
                        ? JSON.parse(
                              localStorage.getItem(
                                  "dashfolioSidebarState"
                              ) as string
                          )
                        : false
                    setSidebarState(newState)
                }

                // Listen for the custom event
                window.addEventListener(
                    "sidebarStateChanged",
                    handleSidebarChange
                )

                // Cleanup
                return () => {
                    window.removeEventListener(
                        "sidebarStateChanged",
                        handleSidebarChange
                    )
                }
            }, [])
        }

        return (
            <span ref={lizardContainerRef}>
                {isActive ? (
                    windowWidth >= 1200 ? (
                        !sidebarState ? (
                            <Component
                                {...props}
                                variant="active"
                                data-variant={"active"}
                            />
                        ) : (
                            <Component
                                {...props}
                                variant="active-small"
                                data-variant={"active"}
                            />
                        )
                    ) : (
                        <Component
                            {...props}
                            variant="active-mobile"
                            data-variant={"active"}
                        />
                    )
                ) : (
                    <Component {...props} data-variant={"inactive"} />
                )}
            </span>
        )
    }
}
